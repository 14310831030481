@import "../../assets/styles/scss/includes/variables";
.input-group-alternative {
  border: 1px solid $oa-gold;
  box-shadow: none;
  -webkit-border-radius: $input-border-radius;
  -moz-border-radius: $input-border-radius;
  border-radius: $input-border-radius;

  input.form-control {
    //color: #666;
    border-color: transparent;
    max-width: calc(100% - 35px);
    &:focus {
      border: 1px solid transparentize($oa-gold, .7);
    }
  }
}
.Combobox {
  // Vars

  // Support
  .input-group-alternative {
    border: 1px solid $oa-gold;
    box-shadow: none;
    -webkit-border-radius: $input-border-radius;
    -moz-border-radius: $input-border-radius;
    border-radius: $input-border-radius;
  }

  // Module
  & {
    //
  }

  // Facets


  .combo-btn {
    padding: 3px 2px;
    cursor: pointer;
    flex: 0 0 35px;
    max-width: 35px;
    svg {
      fill: $oa-gold;
      opacity: .4;
      pointer-events: none;
      max-height: 22px;
    }

    &:hover {
      svg {
        opacity: .8;
      }
      box-shadow: none !important;
      background: #ffffff !important;
    }
  }

  .combo-list-items {
    width: 100%;
    max-height: 329px;
    overflow-y: auto;
    margin: 0;
    z-index: 900;
    border: 1px solid #cad1d7;
    border-top: none;
    border-bottom: none;
    //padding-top: 5px;
    //padding-bottom: 5px;
    //box-shadow: 0 2px 4px rgba(98, 98, 98, 0.25);
    box-shadow: rgba(98, 98, 98, 0.25) 0 2px 4px;

    div {
      padding: 0;
      //box-shadow: 0 2px 2px rgba(4, 53, 64, 0.3);
    }

    //li:first-child{}
  }

  // States
  .disabled{
    background-color: $disabled-bg;
    color: $disabled-fg;
    border: 1px solid $disabled-border-color;
    text-shadow: $disabled-text-shadow;
    pointer-events: none;
    .d-chevron {
      opacity: .9;
      pointer-events: none;
    }
  }

  &.loading-failed {
    border: 1px solid $oa-gold;
  }
}
