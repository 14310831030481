.DashLayout {
  // Vars

  // Support

  // Module
  & {
    //
  }

  // Facets
  main {
    height: 100vh;
    overflow-y: auto;
    .content {

      //height: calc(100vh - 150px);
    }
  }

  // States
}
