@import "../../assets/styles/scss/includes/mixins";
@import "../../assets/styles/scss/includes/variables";
.main {

  //background-image: url(../../assets/backgrounds/home-bg-illustration.svg);
  //background-size: cover;
  position: relative;

  &.login {
    min-height: calc(100vh - #{(($header-padding-y * 2) + 45) + 75}px);
    background: none;
  }

  &:not(.login) {
    &::before {
      //content: '';
      //height: 30%;
      //width: 100%;
      //position: absolute;
      //background-color: rgba(255, 255, 255, .3);
      //background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 212, 255, 0) 100%);
      //bottom: 0;
    }
  }
}

.AuthLayout {
  .login-register-btn {
    min-width: 170px;
  }

  &.login, &.register {
    //background-image: url(../../assets/backgrounds/home-bg-illustration.svg);
    background-repeat: no-repeat;
    background-size: 139%;
    position: relative;
    background-position-x: 57%;
    background-position-y: 46%;
  }

  &::before {
    //content: '';
    //height: 60%;
    //width: 100%;
    //position: absolute;
    //background-color: rgba(255, 255, 255, .3);
    //background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 212, 255, 0) 100%);
    //bottom: 0;
  }

  .recaptcha {
    div {
      margin: 0 auto;
    }
  }
}

//label {
//  top: 6px;
//  position: absolute;
//  opacity: .4;
//  -webkit-transition: all .2s ease;
//  transition: all .2s ease;
//  left: 25px;
//}

.input-wrapper .input, .input-wrapper label {
  //font-size: 13px;
  font-family: segoe ui, helvetica, arial, Open Sans, sans-serif;
}

//label {
//  display: inline-block;
//  margin-bottom: .5rem;
//  padding: .25rem;
//  pointer-events: none;
//}

.divider-or {
  background-color: var(--card-bg);
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);

  @include mobile {
    left: 50%;
    top: 0;
  }
}

button {
  &.facebook, &.google {
    //@include tablet {
      min-width: 130px;
    //}

    & > * {
      pointer-events: none;
    }

    &:hover {
      //background-color: darken(#3B5998, .7) !important;
      box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
      border-color: transparent !important;
    }
    &:active {
      //background-color: darken(#3B5998, .7) !important;
      box-shadow: none;
    }
  }
  &.facebook {
    background-color: #3B5998 !important;
    color: #FFF !important;
  }
  &.google {
    background-color: #fff !important;
    color: #3D3C3A !important;
  }
  &.apple {
    background-color: #000 !important;
    color: #fff !important;
  }
}

.cur- {
  &pointer {
    cursor: pointer;
  }
}

.bg-waves {
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url(../../../public/img/backgrounds/wavy-lines.svg);
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: center center;
    opacity: .6;
  }
}


