@import "includes/variables";

.Header {
  // Vars
  $padding-y: #{$header-padding-y}px;
  // Support

  // Module
  & {
    //height: 70px;
    padding-top: $padding-y;
    padding-bottom: $padding-y;
    //
  }

  // Facets
  .navbar-brand {
    img {
      height: $header-image-height;
    }
  }
  .drop-downs-nav {
    gap: 7px;
  }

  li {
    &.drop-down, .drop-down {
      position: relative;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;

      &:hover, &.open {
        background-color: var(--card-background);
      }


      & > a:first-child, & > div:first-child,  & > div:first-child > div {

        &::before {
          display: none;
          content: '';
          height: 0;
          width: 0;
          border: 10px solid;
          border-color: transparent;
          position: absolute;
          top: calc(100% + 1px);
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 30;
        }

        &::before {
          border-bottom: 10px solid;
          border-bottom-color: var(--card-bg) !important;
          filter: drop-shadow(0 -1px 1px var(--drop-shadow))
        }

        & + div {
          display: none;
          //border: 1px solid $divider-color;
          box-shadow: 0 1px 5px var(--drop-shadow);
          position: absolute;
          top: calc(100% + 10px);
          //left: 50%;
          //transform: translateX(-50%);
          padding: 10px;

          //min-width: 200px;
          border-radius: 4px;
          text-align: left;
          z-index: 22;
          //background-color: #fff;
          //background-image: $accent-gradient-light;
          //max-height: 0;
          right: 0;
          //left: -10px;

          transition: all .2s ease-in-out;

          &:not(.apps-container) {
            overflow: auto;
            width: auto;
          }
          &.apps-container {
            gap: 10px;

            flex-direction: row;

            .oa-app {

            }
          }
        }

        &:hover {
          &:not(.clicked){
            //&::before {
            //  display: block;
            //}
          }

          & + div:not(.clicked) {
            display: block;
          }

          .profile-image {
            border-color: transparentize($oa-gold, .6);
          }
        }

        &.clicked.open {
          &::before {
            display: block;
          }
        }

        & + div.clicked.open, {
          display: flex;
          //flex-direction: column;
        }
      }
    }


    .user {
      position: relative;

      &::before {
        display: none;
        content: '';
        height: 0;
        width: 0;
        border: 10px solid;
        border-color: transparent;
        position: absolute;
        top: calc(100% + 1px);
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 30;
      }

      &::before {
        border-bottom: 10px solid;
        border-bottom-color: var(--card-bg) !important;
        filter: drop-shadow(0 -1px 1px var(--drop-shadow));
      }

      //&:hover. &.is-open {
      &.is-open {
        &::before {
          display: block;
        }

        .user-preview {
          display: block;
          overflow-x: hidden;
        }

        .profile-image {
          border-color: transparentize($oa-gold, .6);
        }
      }
    }

    .profile-image {
      width: 40px;
      height: 40px;
      //border: 2px solid transparent;
      border: 2px solid transparentize($oa-gold, .6);
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      overflow: hidden;
      position: relative;


      &:hover {}


      img {
        width: inherit;
      }
    }

    .user-preview {
      display: none;
      //border: 1px solid $divider-color;
      box-shadow: 0 1px 5px var(--drop-shadow);
      position: absolute;
      top: calc(100% + 10px);
      //left: 50%;
      //transform: translateX(-50%);
      padding: 10px;
      //width: auto;
      //min-width: 50px;
      max-width: 200px;
      border-radius: 6px;
      text-align: left;
      z-index: 22;
      //background-color: #fff;
      //background-image: $accent-gradient-light;
      //max-height: 0;
      right: 0;
      //left: -10px;
      overflow: auto;
      transition: all .2s ease-in-out;

      .profile-image {
        margin: 8px auto;
        width: 60px;
        height: 60px;
      }


    }

    .apps {
      cursor: pointer;
      padding: 0.25rem;
      margin: .25rem;
      border-radius: 50%;


      svg {
        height: 20px;
        width: 20px;
        color: var(--card-icon);
      }

      &:hover {
        svg {
          //fill: $oa-gold;
          color: #000c17;
        }
      }

      &.is-open {
        svg {

        }
      }
    }

    a {
      line-height: 2;
    }
  }


  a {
    color: var(--primary-text);

    &:hover {
      color: var(--accent-color);
    }
  }

  // States
}
