@mixin prefers-color-scheme($color-scheme : 'light') {
  @if ($color-scheme == "dark") {
    :root.dark, :root[data-theme="dark"] {
      @content;
    }

    @media (prefers-color-scheme: dark) {
      :root {
        @content;
      }
    }
  } @else {
    :root {
      @content;
    }
  }
}
