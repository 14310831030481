.swal-overlay {
  background-color: rgba(0, 0, 0, 0.45);
}

.swal-icon {
  border-width: 1px;

  &--error {
    &__line {
      height: 2px;
    }

    &__x-mark {

    }
  }

  &--success {
    &__ring {
      border: 1px solid #3FFF6A;
    }

    &__line {
      height: 2px;

      &--tip {
        width: 25px;
        left: 12px;
        top: 46px;
      }
    }
  }

  &--warning {
    &__body, &__dot {
      position: absolute;
      left: 50%;
      background-color: #f8bb86;
    }
    &__dot {
      width: 4px;
      height: 4px;
      margin-left: -2px;
      bottom: -11px;
    }
  }

}


.swal-modal {
  background-color: var(--card-bg);
  //border: 3px solid white;
}


.swal-title {
  margin: 0;
  font-size: 16px;
  //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.21);
  margin-bottom: 28px;
  color: var(--primary-text);
}

.swal-text {
  //background-color: #FEFAE3;
  //padding: 17px;
  padding: 0 17px;
  //border: 1px solid #F0E1A1;
  display: block;
  margin: 22px;
  margin-top: 10px;
  text-align: center;
  //color: #61534e;
  color: var(--primary-text);
}

.swal-footer {

  //background-color: rgb(245, 248, 250);
  background-color: var(--card-bg);
  //margin-top: 32px;
  margin-top: 12px;
  //border-top: 1px solid #E9EEF1;
  overflow: hidden;
  text-align: center;
  padding-top:0 ;
}

$button-background: $black !default;
$button-hover-background: $obsidian !default;
$button-active-background: $obsidian-graphite !default;
$button-text: $white !default;
$button-text-hover: $white !default;
$button-text-active: $white !default;

.swal-button {
  padding: 7px 19px;
  border: 1px solid $button-background;
  border-radius: 3px;
  background-color: $button-background;
  color: $button-text;
  font-size: 12px;
  text-shadow: none;

  &:not([disabled]):hover, &:focus {
    background-color: $button-hover-background;
    border-color: $button-hover-background;
    color: $button-text-hover;
  }

  &:active {
    background-color: $button-active-background;
    border-color: $button-active-background;
    color: $button-text-active;
  }
}

@include prefers-color-scheme(dark) {
  .swal-button {
    padding: 7px 19px;
    border: 1px solid $button-background-dark;
    border-radius: 3px;
    background-color: $button-background-dark;
    color: $button-text-dark;
    font-size: 12px;
    text-shadow: none;

    &:hover, &:focus {
      background-color: $button-hover-background-dark;
      border-color: $button-hover-background-dark;
      color: $button-text-hover-dark;
    }

    &:active {
      background-color: $button-active-background-dark;
      border-color: $button-active-background-dark;
      color: $button-text-active-dark;
    }
  }
}


.swal-icon--success::after, .swal-icon--success::before, .swal-icon--success__hide-corners {
  background:  var(--card-bg) !important;
}
