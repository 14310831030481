@import "includes/variables";
/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;

  .CircularProgressbar-path, .CircularProgressbar-trail {
    stroke-linecap: round;
  }

  .CircularProgressbar-path {
    stroke: $oa-gold;
    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  .CircularProgressbar-trail {
    stroke: #d6d6d6;
    /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  }

  .CircularProgressbar-text {
    fill: $oa-gold;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
  }
  .CircularProgressbar-background {
    fill: #d6d6d6;
  }

  &CircularProgressbar-inverted {
    /*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
    .CircularProgressbar-background {
      fill: $oa-gold;
    }
    .CircularProgressbar-text {
      fill: #fff;
    }

    .CircularProgressbar-path {
      stroke: #fff;
    }

    .CircularProgressbar-trail {
      stroke: transparent;
    }
  }
}
