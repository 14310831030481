.NOT_FOUND_PAGE{
  //background-image: url('../../../public/img/backgrounds/Group 21.svg');

  .bg-art {
    height: 50vh;

    img {
      height: 100%;
    }
  }
}
