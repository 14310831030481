@use "sass:math";

:root {
  --text-color: #0078ff;
}

/*  typography */
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

/*!** Generated by FG **!
@font-face {
  font-family: 'NewYorkRegular';
  src: url('../../../fonts/NewYorkSmall-Regular.eot');
  src: local('☺'), url('../../../fonts/NewYorkSmall-Regular.woff') format('woff'), url('../../../fonts/NewYorkSmall-Regular.ttf') format('truetype'), url('../../../fonts/NewYorkSmall-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NewYork';
  src: local('☺'), url('../../../fonts/new-york/NewYork.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}*/

body {
  line-height: 1.2;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  //font-size: 15px;
  color: $text-color;
  //line-height: 1.7;
  //color: lighten($black, 50%);
  font-weight: 300;
  font-size: $font-size-base;
}

p, .paragraph {
  font-weight: 400;
  color: var(--primary-text);
  font-size: 15px;
  line-height: 1.9;
  font-family: $primary-font;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary-text);
  font-family: $primary-font;
  font-weight: 400;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 60px;
  @include mobile {
    font-size: 45px;
  }
}

h2, .h2 {
  font-size: 40px;
  @include mobile {
    font-size: 30px;
  }
}

h3, .h3 {
  font-size: 25px;
  @include mobile {
    font-size: 20px;
  }
}

h4, .h4 {
  font-size: 20px;
  @include mobile {
    font-size: 18px;
  }
}

h5, .h5 {
  font-size: 18px;
  @include mobile {
    font-size: 16px;
  }
}

h6, .h6 {
  font-size: 16px;
  @include mobile {
    font-size: 14px;
  }
}

.text {
  &-muted {
    //color: rgba(108, 117, 125, 0.57) !important;
    color: var(--primary-muted-text) !important;
  }

  &-muted-2 {
    color: var(--primary-muted-text-2) !important;
  }

  &-secondary-muted {
    color: var(--secondary-muted-text) !important;
  }

  &-secondary-muted-2 {
    color: var(--secondary-muted-text-2) !important;
  }

  &-smaller {
    font-size: .6em;
  }

  &-small {
    font-size: .75em;
  }

  &-normal, &-regular {
    font-size: 1em;
  }

  &-medium {
    font-size: 1.2em;
  }

  &-large {
    font-size: 1.5em;
  }

  &-larger {
    font-size: 1.8em;
  }
}

.text- {
  &oa-gold {
    color: $oa-gold !important;
  }

  &oa-dark {
    color: $oa-dark-gray;
  }

  &secondary-font {
    font-family: "NewYork", serif;
  }
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height {
  @for $i from 11 through 20 {
    &-#{$i} {
      line-height: #{math.div($i, 10)} !important;
    }
  }
}
