@import "../../assets/styles/scss/includes/variables";

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: var(--card-bg);
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  //padding: 15px;
  //overflow: auto;

  &.input-sibling {
    //max-width: 280px;
  }

  &.dash-cal{
    width: 100%;
  }

  &--doubleView {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      & > * {
        width: 50%;
        margin: 0.5em;
      }
    }
  }

  *, *:before, *:after, & {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;
    flex-basis: border-box;

    &:enabled:hover {
      cursor: pointer;
    }
  }

  &__navigation {
    height: 44px;
    margin-bottom: 1em;

    button {
      min-width: 44px;
      color: var(--primary-text);
      background: none;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;

      &:enabled:hover, &:enabled:focus {
        //background-color: #e6e6e6;
        //background: #a6ff00;
        //background: $budha-gold;
        //background: #ffb700;
        //background: $cerulean;
        background-color: var(--input-outline);
        //color: #000;
        color: var(--primary-text);
        font-weight: 600;
      }

      &[disabled] {
        background-color: var(--disabled-bg);
        color: var(--disabled-text);
        text-shadow: 1px 1px 0 #b3b0b0;
      }
    }
  }
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  //padding: 0.75em 0.5em;
  padding: 8px 2px;
  margin: 2px 6px !important;
  background: none;
  width: 32px !important;
  height: 32px !important;
  color: var(--primary-text);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  &:enabled:hover, &:enabled:focus {
    //background-color: #e6e6e6;
    color: var(--primary-text);
    //background: #98C8FF;
    background-color: var(--card-hover-outline);
    font-weight: 600;
  }

  &:disabled {
    background-color: var(--disabled-bg);
    color: var(--disabled-text);
    text-shadow: var(--disabled-text-shadow);
  }

  &--now {
    //background: #ffff76;
    //background: #a6ff00;
    background: var(--obsidian-gold-neue);
    color: #000;
    font-weight: 600;

    &:enabled:hover, &:enabled:focus {
      //background: #ffffa9;
      background: #a6ff00;
      color: #000;
    }
  }

  &--hasActive {
    background: #76baff;

    &:enabled:hover, &:enabled:focus {
      background: #a9d4ff;
    }
  }

  &--active {
    //background: #006edc;
    //color: white;

    //background: #D9FF00;
    background: #a6ff00;
    //background: #ffaa00;
    //background: $cerulean;
    color: #000;
    font-weight: 600;

    &:enabled:hover, &:enabled:focus {
      background: #1087ff;
    }
  }
}
.react-calendar__month-view {
  &__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;

    background: var(--obsidian-gold-neue);
    color: #000;

    &__weekday {
      //padding: 0.5em;
      padding: 5px !important;

    }
  }

  &__weekNumbers {
    font-weight: bold;

    .react-calendar__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75em;
      //padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
    }
  }

  &__days {
    justify-content: space-between;

    &__day {

      &--weekend {
        color: var(--obsidian-gold-neue);
        //background: rgba(206, 141, 208, 0.86) !important;
      }

      &--neighboringMonth {
        //color: #757575;
        color: var(--primary-muted-text-2)
      }
    }
  }
}

.react-calendar__month-view__days, .react-calendar__year-vih-view, .react-calendar__decade-view, .react-calendar__century-view {
  transition: max-height .1s cubic-bezier(.17, .95, .39, 1.04);
  //max-height: 0;
  max-height: 300px;
}


.react-calendar__year-view, .react-calendar__decade-view, .react-calendar__century-view {
  .react-calendar__decade-view__years {
    justify-content: space-around;
  }

  .react-calendar__tile {
    padding: 2px;
  }
}




.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

abbr[title], abbr[data-original-title] {
  cursor: default !important;
}


/*#app {
  background: #fff;
  !* border-radius: 4px; *!
  padding: 20px;
  transition: all 0.2s;
}

section {
  &.calender-widget {
    !* width: 250px; *!
    display: inline-block;
    min-height: 25px;
    padding: 5px;
    overflow: hidden;
    position: relative;
  }

  .head {
    height: 30px;
    width: calc(100% - 9px);
    padding: 10px 4px;
    display: inline-block;
    text-align: center;
    background: rgba(204, 204, 204, 0.89);

    div {
      display: inline-block;
    }

    .nav-buttons {
      button {
        background: #ffffff;
        !*border: none;*!
        height: 30px;
        width: 30px;
        text-align: center;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 5px;
        !*padding-top:5px;*!
        cursor: pointer;
        border: 2px solid transparent;

        img {
          width: 10px;
        }

        &:hover {
          border-color: #0078ff;
          background: #ffb700;
        }
      }

      &.right {
        button {
          img {
            rotate: 180deg;
          }
        }
      }
    }

    .mon-year-sel {
      margin: 0 auto;

      span {
        padding: 5px 12px;
        cursor: pointer;
        border-radius: 15px;
        border: 2px solid transparent;

        &:hover {
          border-color: #0078ff;
          background: #98C8FF;
        }
      }
    }

  }

  .mon-cal, .year-cal {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
  }
  .mon-cal {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    div {
      width: 30%;
      height: 30%;
    }
  }
}

table {
  border: 1px solid #ccc;
  background: #ffffff;

  thead {
    background: #0078FF;
  }

  tr {
    min-height: 34px;
  }

  td, th {
    text-align: center;
    padding: 15px;
  }

  th {
    font-weight: 600;
    color: #fff;
    padding: 15px;
  }

  td {
    cursor: pointer;
    padding: 8px;

    div {
      pointer-events: none;
      padding: 7px;
      border-radius: 50%
    }

    &:hover > div {
      color: #ffffff;
      background: #98C8FF;
      font-weight: 600;
    }

    &.dumb {
      cursor: default;
      > div {
        color: #ccc;
      }
    }

    &.dumb:hover > div {
      color: #ccc;
      background: #FFF;
    }

    &.selected > div {
      background: #0078FF;
      color: #fff;
      font-weight: 600;
    }

    &.today {

      > div {
        background: #D9FF00;
        color: #000;
        font-weight: 600;
      }

      &.selected > div {
        background: #ffb700;
        color: #fff;
      }
    }
  }

  &.full-cal {
  }
}*/

div.react-calendar.input-sibling.m-auto.p-3 div.react-calendar__viewContainer div.react-calendar__month-view div div div.react-calendar__month-view__days button.react-calendar__tile {
  @include _mobile_xxs {
    flex-basis: 22px !important;
  }

  @include _mobile_xs {
    flex-basis: 28px !important;
  }

  @include _mobile {
    flex-basis: 33px !important;
  }

  @include _tablet {
    flex-basis: 33px !important;
  }

}

div.react-calendar.input-sibling.m-auto.p-3 div.react-calendar__viewContainer div.react-calendar__year-view div.react-calendar__year-view__months button.react-calendar__tile.react-calendar__year-view__months__month {
  flex-basis: 31% !important;

}


