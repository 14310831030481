$blue-lagoon: #007E92;
$cerulean: #00B0CA;

$success: #52c41a;
$info: #1890ff;
$warning: #faad14;
$alert: #faad14;
$error: #ff4d4f;
$online: $cerulean;
$offline: #4c4c56;

.notification {
  width: fit-content !important;
  pointer-events: none;
}

.notification__item {
  display: flex;
  position: relative;
  border: 1px solid #f8e9d6;
  border-radius: 5px;
  margin-bottom: 15px;
  //-webkit-box-shadow: 0 3px 6px -4px rgba(191, 144, 80, 0.12), 0 6px 0px 0 rgba(191, 144, 80, 0.08), 0 9px 28px 4px rgba(191, 144, 80, 0.15);
  //box-shadow: 0 3px 6px -4px rgba(191, 144, 80, 0.12), 0 6px 0px 0 rgba(191, 144, 80, 0.08), 0 9px 28px 4px rgba(191, 144, 80, 0.15);
  -webkit-box-shadow: 0 0 3px 2px rgba(191, 144, 80, 0.08), 0 7px 10px rgba(191, 144, 80, 0.15);
  box-shadow: 0 0 3px 2px rgba(191, 144, 80, 0.08), 0 7px 10px rgba(191, 144, 80, 0.15);
  cursor: pointer;
}

.notification__parent {
  width: fit-content !important;
}
.notification-title {
  margin-bottom: 3px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
  padding-right: 24px;
}

.notification-content {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
// Add properties
.notification-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 250px;
  min-height: 60px;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 16px 24px 16px 16px;
  background: white;

  .icon-notification {
    width: 30px;
    padding-right: 10px;
    padding-top: 3px;
  }

  &.colored {
    .notification-title, .notification-content{
      color: #fff;
    }

    .notification-close {
        color: rgba(255, 255, 255, 0.5);

      &:hover {
        color: rgba(255, 255, 255, 0.85);
      }
    }

    &.success {
      background: $success;
    }
    .icon {fill: #fff;}

    &.info {background: $info;}

    &.warning {background: $warning;}

    &.error {background: $error;}

    &.online {background: $online;}

    &.offline {background: $offline;}

    &.alert {
      background: $alert;

      .notification-title, .notification-content {
        color: $offline;
      }
    }
  }
}

.message-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 20px;
  border: none;
  padding: 8px 16px;
  background: white !important;
}

.notification-close {
  pointer-events: all;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
  right: 10px;
  top: 10px;

  &::after {
    content: '' !important;
  }

  &:before {
    content: '✕';
  }
  &:hover {
     color: rgba(0, 0, 0, 0.85);
   }
}

.notification-buttons {
  button {
    pointer-events: all;
  }
}


.elementTopFadeIn {
  -webkit-animation: fadeinTop 300ms linear forwards;
  animation: fadeinTop 300ms linear forwards;
}

.elementTopFadeOut {
  -webkit-animation: fadeoutTop 300ms linear forwards;
  animation: fadeoutTop 300ms linear forwards;
}

.elementToFadeIn {
  -webkit-animation: fadein 300ms linear forwards;
  animation: fadein 300ms linear forwards;
}

.elementToFadeOut {
  -webkit-animation: fadeout 300ms linear forwards;
  animation: fadeout 300ms linear forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(350px);
  }
}

@keyframes fadeinTop {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeoutTop {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-100px);
  }
}

.rotate {
  transform: rotate(-90deg);
}

//.icon {
//  width: 20px;
//  height: 20px;
//}

.icon-success {
  .icon {
    fill: $success;
  }
}

.icon-info {
  .icon {
    fill: $info;
  }
}

.icon-warning {
  .icon {
    fill: $warning;
    transform: rotate(180deg);
  }
}

.icon-error {
  .icon {
    fill: $error;
  }
}

.icon-online {
  .icon {
    fill: $online;
  }
}

.icon-offline {
  .icon {
    fill: $offline;
  }
}

.icon-message {
  padding-right: 10px;

  .icon {
    width: 20px;
    height: 20px;
  }
}
