// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons


@mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
  color: color-yiq($background);
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  @include hover {
    color: color-yiq($hover-background);
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
    } @else {
      box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
      }
    }
  }
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: $color !important;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  &:hover {
    color: $color-hover !important;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }
}

@mixin button-link-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: $color-hover !important;
  background-color: transparent;
  background-image: none;
  border-color: transparent;

  &:hover {
    color: darken($color, 12.5%) !important;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }
}


@mixin button-variant-2($background, $color, $border, $hover-background, $hover-color, $hover-border, $active-background, $active-border, $active-color) {
  color: $color;
  background-color:$background;
  border-color: $border;

  &:hover {
    color: $hover-color;
    background-color: $hover-background;
    border-color: $hover-border;
  }

  &:hover {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus, &.focus {
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($accent-color, .5);
    } @else {
      box-shadow: 0 0 0 $btn-focus-width rgba($accent-color, .5);
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled, &:disabled {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
      }
    }
  }
}

@mixin button-outline-variant-2($color, $hover-color, $active-color) {
  color: $color !important;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  &:hover {
    color: $hover-color !important;
    background-color: transparent;
    border-color: $hover-color;
  }

  &:active {
    color: $active-color !important;
    background-color: transparent;
    border-color: $active-color;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }
}

@mixin button-link-variant-2($text, $hover-text, $active-text) {
  color: $text !important;
  background-color: transparent;
  background-image: none;
  border-color: transparent;

  &:hover {
    color: $hover-text !important;
  }

  &:hover {
    color: $active-text !important;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($text, .5);
  }

  &.disabled,
  &:disabled {
    color: $text;
    background-color: transparent;
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @if $enable-rounded {
    border-radius: $border-radius;
  } @else {
    border-radius: 0;
  }
}
