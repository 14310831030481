$xxs: 250px;
$xs: 375px;
$sm: 575px;
$md: 768px;
$lg: 991px;
$lg-2: 1200px;
$xl: 1400px;
$kindle: 800px;
$ipad: 810px;

@mixin mobile-xs {
  @media(max-width: $xs) {
    @content;
  }
}

@mixin _mobile-xs {
  @media(min-width: $xs) {
    @content;
  }
}

@mixin _mobile-xxs {
  @media(min-width: $xxs) {
    @content;
  }
}

@mixin mobile {
  @media(max-width: $sm) {
    @content;
  }
}

@mixin _mobile {
  @media(min-width: $sm) {
    @content;
  }
}

@mixin tablet {
  @media(max-width: $md) {
    @content;
  }
}

@mixin _tablet {
  @media(min-width: $md) {
    @content;
  }
}

@mixin kindle {
  @media(max-width: $kindle) {
    @content;
  }
}

@mixin ipad {
  @media(max-width: $ipad) {
    @content;
  }
}

@mixin _kindle {
  @media(min-width: $kindle) {
    @content;
  }
}

@mixin desktop {
  @media(max-width: $lg) {
    @content;
  }
}

@mixin _desktop {
  @media(min-width: $lg) {
    @content;
  }
}

@mixin desktop-lg {
  @media(max-width: $lg-2) {
    @content;
  }
}

@mixin desktop-xl {
  @media(max-width: $xl) {
    @content;
  }
}

@mixin _desktop-lg {
  @media(min-width: $lg-2) {
    @content;
  }
}

@mixin _desktop-xl {
  @media(min-width: $xl) {
    @content;
  }
}

@mixin short_screen {
  @media(max-height: 508px) {
    @content;
  }
}

@mixin size($size) {
  width: $size;
  height: $size;
}

