.flex {
  display: flex;
}

$v-alignments : top, super, middle, bottom, sub;

.vertical-align- {
  @each $alignment in $v-alignments {
    &#{$alignment} {
      vertical-align: $alignment;
    }
  }
}

.quote-marks {
  opacity: .5;
  font-size: 188px;
  display: block;
  height: 150px;
  font-family: arial sans-serif;
  color: #fe2344;
  z-index: 0;
}

.disabled {
  background-color: var(--disabled-bg) !important;
  color: var(--disabled-fg) !important;
  border: 1px solid var(--disabled-border-color) !important;
  text-shadow: var(--disabled-text-shadow) !important;
  pointer-events: none;
  box-shadow: none !important;

  .d-chevron {
    opacity: .9;
    pointer-events: none;
  }
}

.hint-icon {
  width: 23px;
  display: inline-block;
  text-align: right;
  cursor: pointer;
  position: relative;

  //&::before, &::after {
  &::before {
    display: none;
    content: '';
    height: 0;
    width: 0;
    border: 10px solid;
    border-color: transparent;
    position: absolute;
    top: calc(100% + 2px);
    left: calc(100% - 15px);
    transform: translate(-50%, -50%);
    z-index: 30;
  }

  &::before {
    border-bottom: 10px solid;
    border-bottom-color: var(--card-bg) !important;
    filter: drop-shadow(0 -1px 1px rgba(62, 97, 128, 0.32))
  }

  /*&::after {
    border-bottom: 10px solid;
    border-bottom-color: #FFFFFF !important;
  }*/

  &:hover, &.show {
    & > .validation-hint {
      display: block;
    }

    &::before, &::after {
      display: block;
    }
  }

  .validation-hint {
    display: none;
    //border: 1px solid $divider-color;
    box-shadow: 0 1px 3px rgba(62, 97, 128, 0.16);
    position: absolute;
    top: calc(100% + 10px);
    //left: 50%;
    //transform: translateX(-50%);
    padding: 10px;
    width: auto;
    min-width: 200px;
    border-radius: 2px;
    text-align: left;
    z-index: 22;
    background-color: var(--card-bg);
    //background-image: $accent-gradient-light;
    //max-height: 0;
    right: -12px;
    overflow: auto;
    transition: all .2s ease-in-out;

    &.left {
      left: 0;
    }

    .heading {
      //font-size: .85em;
      font-family: $primary-font;
      font-weight: 600;
    }

    .format {
      display: block;
    }
  }

  &.right {
    text-align: left;

    .validation-hint {
      right: unset;
      left: -10px;
    }
  }
}

.form-section-title {
  border-left: 7px solid var(--obsidian-gold-neue);
  padding-left: 5px;
  font-size: .85rem;
  font-weight: 600;
}

.cur, .cursor {
  &-pointer {
    cursor: pointer;
  }

  &sor {
    &-pointer {
      cursor: pointer;
    }
  }
}

.pointer-events {
  &-none {
    pointer-events: none;
  }
  &-all {
    pointer-events: all;
  }
}

//////

.welcome-container {
  height: calc(100vh - 105px);
}




