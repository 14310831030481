@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

/* Base */
@import "includes/site-base";
/* Navbar */
@import "includes/site-navbar";
/* Blocks */
//@import "includes/site-blocks";

/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT:	Project Name
VERSION:	Version Number
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[TABLE OF CONTENTS]
-------------------------------------------------------------------*/

//@import 'includes/variables';

@import 'includes/mixins';

@import 'includes/typography';

@import 'includes/buttons';

@import 'bootstrap/utilities/helper';
@import 'bootstrap/utilities/sizing';

//@import "templates/site-blocks";

//@import 'templates/navigation';

//@import 'templates/slider';

//@import 'templates/homepage';

//@import 'templates/otherspage';

@import "dash-home";

@import "sweet-alert";
@import "components";
@import "main";
