@import "includes/variables";
@import "includes/mixins";

.SideBar {
  $item-hover-color: #805708;

  $active-color: #8d5e03;

  height: 100vh;
  display: flex;
  transition: all .35s ease-in-out;
  z-index: 4;

  //&.__mob {
  //  position: absolute;
  //  left: 0;
  //}

  @include tablet {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.68);
  }

  @include _desktop {
    &.__hidden {
      position: relative !important;
      left: 0 !important;
      width: unset;
    }
  }

  &.__hidden {
    position: absolute;
    left: -300px;
    width: unset;
  }

  .sidebar {
    //min-width: 250px;
    max-width: 250px;
    background-color: var(--card-bg);
    //background-image: $sidebar-gradient;
    color: #000;
    transition: all 0.3s cubic-bezier(1, 0, 0, 1);
    margin: 0;
    height: 100%;
    //box-shadow: $sidebar-shadow;

    &.__main {
      @include desktop {
        background-image: url(../../../components/SideBar_/sidebar-waves.svg) $sidebar-gradient;
        background-repeat: no-repeat;
        background-position: center bottom;
      }
    }

    &.__sub {
      background-color: #9D741D;

      ul {
        //padding-left: 0;
        //padding-right: 0;
        &.components {
        }

        p {
        }

        li {
          &:last-child {
            //border-bottom: 1px solid #FAD388;
          }

          &.divider {
          }

          a {
            font-size: .8em;
            display: block;
            color: #000;
            position: relative;
            padding: 12px 20px;
            padding-right: 10px;

            span {
              display: inline-block;
              transition: all .2s ease-in-out;

              &.icon {
                //height: 20px;
                //width: 20px;

                svg {
                  //display: block;
                }
              }
            }

            &::before {
              content: '';
              //height: 90%; // msg uncomment this and the next line and comment out the
              //width: 3px; //   two line following the next for block style highlighting
              height: 0;
              width: 0;
            }

            &:hover, &.active, &:active, &:focus {
              padding-left: 20px;

              span {
                &.icon {
                }
              }

              svg {
              }

              &::before {
                display: none;
              }
            }
          }

          &.active > a,
          a[aria-expanded="true"] {
            color: #000;
            //background: #6d7fcc;
          }
        }
      }
    }

    @include _tablet {
      //padding-top: 60px;
    }

    @include short_screen {
      padding-top: 0;
    }

    &.active {
      margin-left: -250px;
    }

    .sidebar-header {
      padding: 20px;
      min-height: 80px;

      @include _tablet {
        margin-bottom: 30px;
      }
      //background: #6d7fcc;

      .user {
        & > div {
          display: flex;

          & > * {
            margin: auto;
          }
        }

        .__image {
          height: 40px;
          width: 40px;

          img {
            height: inherit;
            width: inherit;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            margin: auto;
          }
        }

        .__name {
        }

        .__actions {
          width: 20px;
        }
      }
    }

    .sidebar-list {
      //min-height: calc(100% - 300px);
      max-height: calc(100% - 300px);
      //overflow-y: auto;

      @include short_screen {
        max-height: calc(100% - 100px);
      }
    }

    ul {
      &.components {
        padding: 20px 0;
        border-bottom: 1px solid #47748b;
      }

      p {
        color: #000;
        padding: 20px 35px;
        padding-right: 10px;
      }

      padding-left: 5px;
      padding-right: 7px;

      li {
        //border-top: 1px solid #FAD388; // msg uncomment this to reactivate dividers
        //border-right: none;
        //border-left: none;

        &:last-child {
          //border-bottom: 1px solid #FAD388;
        }

        &.divider {
          border-bottom: 1px solid #FAD388;
          height: 0;
          width: 80%;
          max-width: 190px;
          margin: 0 auto;
          pointer-events: none;
          margin-top: 2px;
          margin-bottom: 2px;
        }

        a {
          font-size: .8em;
          display: block;
          color: var(--primary-text);
          position: relative;
          padding: 12px 20px;
          padding-right: 10px;

          span {
            display: inline-block;
            transition: all .2s ease-in-out;

            &.icon {
              //height: 20px;
              //width: 20px;

              svg {
                //display: block;
              }
            }
          }

          &::before {
            content: '';
            //height: 90%; // msg uncomment this and the next line and comment out the
            //width: 3px; //   two line following the next for block style highlighting
            height: 2px;
            width: 12px;
            background-color: var(--link-text);
            display: none;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          &:hover, &.active, &:focus {
            color: var(--link-text);
            stroke: var(--link-text);
            fill: var(--link-text);
            font-weight: 500;
            padding-left: 25px;

            span {
              &.icon {
              }
            }

            &::before {
              display: block;
            }
          }

          &:active {
            color: var(--obsidian-gold);
            stroke: var(--obsidian-gold);
            fill: var(--obsidian-gold);

            >::before {
              background-color: var(--obsidian-gold);
            }
          }
        }

        &.active > a,
        a[aria-expanded="true"] {
          color: #fff;
          //background: #6d7fcc;
        }
      }
    }

    .sidebar-footer {
      position: absolute;
      bottom: 200px;
      padding: 20px;
      margin-top: 60px;

      @include short_screen {
        margin-top: 3px;
      }

      @include tablet {
        position: relative;
        bottom: 0;
      }
    }

    .brand {
      color: $active-color;

      div {
        display: flex;

        & > * {
          margin: auto;
        }
      }

      .__name p {
        color: $active-color;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }

    .beta-badge {
      padding: 1px 2px 1px 4px;
      font-size: .5em;
      letter-spacing: 2px;
      border: 1px solid $active-color;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }

    .user-menu-list {
      background-color: $oa-matte-color-2;
      right: -6px;
      top: 100%;
      z-index: 5;
      filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.1));
      transition: all .3s ease-in-out;

      ul {
        padding-right: 5px;

        li {
          &:first-child {
            border-top: 0;
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      a {
        color: $oa-dark-gray !important;
        padding: 6px 10px;
        //padding-right: 15px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        font-weight: 400;

        &:hover, &:active, &:focus {
          color: #fff !important;
          padding-left: 10px !important;
          background-color: #BC984A;

          &::before {
            display: none !important;
          }
        }

        &:first-of-type {
          border-top: 0;
        }

        &:last-of-type {
          border-bottom: 0;
        }
      }

      &::before, &::after {
        content: '';
        height: 0;
        width: 0;
        border: 7px solid transparent;
        position: absolute;
        top: -14px;
        right: 10px;
      }

      &::before {
        border-bottom-color: #fff;
      }

      &::after {

      }
    }

    &.collapsed {
      .sidebar-header, .sidebar-footer {
        padding-right: 0;
      }

      .sidebar-header {
        .user {
          transform: scale(.8);
          transform-origin: left;
          z-index: 1;

          .__img {
            //width: 40px;
          }

          .__name {
            display: none;
          }

          .user-menu-list {
            left: calc(100% + 20px);
            right: unset;
            top: 15px;
            min-width: 120px;
            text-align: left;
            transform: scale(1.2);

            &::before {
              top: 11px;
              right: 100%;
              border-bottom-color: transparent;
              border-right-color: #fff;
            }
          }
        }
      }

      ul {
        &.components {
          padding: 20px 0;
          border-bottom: 1px solid #47748b;
        }

        li {

          a {

            span {
              transition: all .2s ease-in-out;

              &.name {
                display: none;
                position: absolute;
                padding: 8px 10px;
                background: #1c1a1a;
                left: -webkit-calc(100% - 5px);
                left: -moz-calc(100% - 5px);
                left: calc(100% - 5px);
                z-index: 5;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                transform: translateY(-50%);
                border-radius: 3px;
                width: auto;

                &::before {
                  content: '';
                  position: absolute;
                  height: 0;
                  width: 0;
                  border: 6px solid transparent;
                  border-right-color: #1c1a1a;
                  display: block;
                  left: -12px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }

              &.icon {
              }
            }

            &:hover, &:active, &:focus {
              span {

                &.name {
                  display: inline-block;

                }
              }
            }
          }
        }
      }

      .sidebar-footer {
        .brand {
          img {
            width: 35px;
          }
        }

        .__name {
          display: none;
        }

        .__status {
          transition: .3s ease-in-out;
          position: absolute;
          top: -14px;
          left: 40%;
          transform: scale(.8);
        }
      }
    }
  }

}
