@import "includes/mixins";
.Header {
  &.dash, &.dash .container,  {
    position: relative;
  }
}
.nav-menu {
  @include tablet{
    //display: none;
  }

  @include tablet{
    position: absolute;
    top: calc(100% + 10px);
    right: -500px;

    border: 1px solid #bf9050;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

    transition: all .2s ease-in-out;

    &::before, &::after {
      position: absolute;
      content: '';
      display: block;
      border: 10px solid transparent;
      border-bottom-color: #bf9050;
      top: -20px;
      right: 7px;
    }

    &::after {
      border-bottom-color: #fff;
      top: -18.5px;
    }
  }

  &.__is-open {
    //display: block;

    @include tablet{
      right: -10px;
    }
  }
}
