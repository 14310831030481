@import "../../assets/styles/scss/includes/variables";

.DatePicker {
  // Vars

  // Support
  .input-group-alternative {
    //border: 1px solid $oa-gold;
    box-shadow: none;
    //-webkit-border-radius: 2px;
    //-moz-border-radius: 2px;
    //border-radius: 2px;
  }

  // Module
  & {
    //
  }

  // Facets
  input.form-control {
    max-width: calc(100% - 35px);
    border-color: transparent;

    &:focus {
      border: 1px solid transparentize($oa-gold, .7);
    }
  }

  .combo-btn {
    padding: 2px 2px;
    cursor: pointer;
    flex: 0 0 35px;
    max-width: 35px;
    background-color: transparent;

    svg {
      opacity: .5;
      pointer-events: none;
      max-height: 25px;
      fill : var(--icon-hover)
    }

    &:hover {
      svg {
        opacity: .8;
        fill : var(--icon-hover)
      }

      box-shadow: none !important;
    }
  }

  .calendar-container {
    background-color: var(--card-bg);
    width: 100%;
    max-height: 329px;
    overflow-y: hidden;
    margin: 0;
    z-index: 900;
    //border: 1px solid #cad1d7;
    border-top: none;
    border-bottom: none;
    transition: max-height .2s ease-in;
    //padding-top: 5px;
    //padding-bottom: 5px;
    //box-shadow: 0 2px 4px rgba(98, 98, 98, 0.25);
    box-shadow: rgba(98, 98, 98, 0.25) 0 2px 4px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    div {
      padding: 0;
      //box-shadow: 0 2px 2px rgba(4, 53, 64, 0.3);
    }

    //li:first-child{}
  }

  // States
  .disabled {
    background-color: $disabled-bg;
    color: $disabled-fg;
    border: 1px solid $disabled-border-color;
    text-shadow: $disabled-text-shadow;
    pointer-events: none;

    .d-chevron {
      opacity: .9;
      pointer-events: none;
    }
  }

  &.loading-failed {
    border: 1px solid #f00;
  }
}
