/* Button style */
.btn {
  font-size: 12px;
  font-family: $primary-font;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: $border-radius;
  font-weight: 500;
  border: 1px solid transparent;
  position: relative;
  z-index: 1;
  transition: all .4s ease-in-out;
  overflow: hidden;

  &:focus {
    outline: 0;
  }

  &:active {
    box-shadow: none;
  }

  //&:hover {
  //
  //  &::before {
  //    //transform: scaleY(1);
  //    //transform-origin: bottom;
  //  }
  //}

  //&-primary {
  //  background: $accent-gradient;
  //  color: $white !important;
  //  box-shadow: $box-shadow-dark;
  //
  //  &:hover {
  //    background: $accent-gradient-light;
  //    color: $oa-gold !important;
  //    border: 1px solid $oa-gold;
  //  }
  //
  //  &._light {
  //    background-color: $oa-matte-color-3;
  //    color: $accent-color;
  //  }
  //
  //  &:not(:disabled):not(.disabled).active,
  //  &:not(:disabled):not(.disabled):active {
  //    color: $white;
  //    background-color: darken($color: $primary-color, $amount: 10);
  //    border-color: darken($color: $primary-color, $amount: 10);
  //  }
  //}
  //
  //&-secondary {
  //  background: $accent-gradient-light;
  //  color: $oa-gold;
  //  box-shadow: $box-shadow-light;
  //
  //  &:hover {
  //    background: $accent-gradient-light;
  //    color: $oa-gold;
  //    border: 1px solid $oa-gold;
  //  }
  //}
  //
  //&-outline {
  //  &-primary {
  //    border: 1px solid $primary-color;
  //    color: $primary-color;
  //    background: transparent;
  //
  //    &::before {
  //      background: $white;
  //    }
  //
  //    &:hover {
  //      background: $primary-color;
  //      color: $primary-color;
  //    }
  //
  //    &:active {
  //      background: $primary-color;
  //      color: $white;
  //    }
  //  }
  //
  //  &-secondary {
  //    border: 1px solid $primary-color;
  //    color: $primary-color;
  //    background: transparent;
  //
  //    &::before {
  //      background: $white;
  //    }
  //
  //    &:hover {
  //      background: $primary-color;
  //      color: $primary-color;
  //    }
  //
  //    &:active {
  //      background: $primary-color;
  //      color: $white;
  //    }
  //  }
  //
  //  &-danger {
  //    border: 1px solid $primary-color;
  //    color: $primary-color;
  //    background: transparent;
  //
  //    &::before {
  //      background: $white;
  //    }
  //
  //    &:hover {
  //      background: $primary-color;
  //      color: $primary-color;
  //    }
  //
  //    &:active {
  //      background: $primary-color;
  //      color: $white;
  //    }
  //  }
  //
  //  &-dark {
  //    border: 1px solid $primary-color;
  //    color: $primary-color;
  //    background: transparent;
  //
  //    &::before {
  //      background: $white;
  //    }
  //
  //    &:hover {
  //      background: $primary-color;
  //      color: $primary-color;
  //    }
  //
  //    &:active {
  //      background: $primary-color;
  //      color: $white;
  //    }
  //  }
  //}

  &-sm {
    font-size: 14px;
    //padding: 10px 35px;
  }

  &-xs {
    font-size: 12px;
    //padding: 5px 15px;
  }
}

.show>.btn-primary.dropdown-toggle {
  color: $white;
  background-color: darken($color: $primary-color, $amount: 10);
  border-color: darken($color: $primary-color, $amount: 10);
}
